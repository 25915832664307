<template>
    <transition :name="animateName">
        <div
            v-show="showFooter"
            id="gridFooter"
            v-hovermenu="hovermenu"
            class="jz_web_footer g_footer"
            :class="activeFooterClass"
            :style="footerStyle"
            ><jz-web-rows :ids="footerRowIds"></jz-web-rows
        ></div>
    </transition>
</template>

<script>
// @before-enter="beforeEnter" @after-enter="afterEnter"
import { initDomMutationObserver } from '@/site/shared/mutationObserver/index.js';
const manageUse = {};
// #!if manageMode
import hovermenu from '@/manage/plugins/hovermenu/gridFooter';
manageUse.hovermenu = hovermenu;
// #!endif
import { getComputedBg } from '@/manage/designer/initContext/utils';
const FOOTER_MODULE_IDS = 152;
const SYS_FOOTER = 4;
export default {
    name: 'grid-footer',
    props: {
        info: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...Vuex.mapState(['device', 'rows', 'hasDomainSupportIpv6']),
        ...Vuex.mapGetters(['isFullScreen']),
        ...Vuex.mapState('row', ['rollingScreenRowInfo']),
        ...Vuex.mapState('manage', ['jzPreviewAreaHeight', 'pageTop']),
        ...Vuex.mapState('header', ['headerStyleStatus']),
        ...Vuex.mapState('footer', ['footerStyleStatus']),
        isMobi() {
            return this.device === 'mobi';
        },
        pattern() {
            return this.info.pattern || {};
        },
        footer() {
            return this.pattern.footer || {};
        },
        background() {
            return this.footer.background || {};
        },
        // 文字设置暂不开设
        // font() {
        // 	return this.footer.font || {};
        // },
        copyright() {
            return this.footer.copyright || {};
        },
        isShowVideoBg() {
            return this.$store.getters.isShowVideoBg(this.sysFooterRow);
        },
        footerStyle() {
            const backgroundStyle = getComputedBg(this.background);
            // 文字设置暂不开设
            // const fontStyle = this.font.y == 1 ? {
            // 	'font-size': this.font.s + 'px',
            // 	'font-family': this.font.f,
            // 	'color': this.font.c
            // } : {};
            let fullScreenStyle = {};
            if (this.isFullScreen) {
                if (this.rollingScreen.bottomType == 1) {
                    let top = 0;
                    let headerLevitate = this.rollingScreen.topType === 1 && !this.isMobi;
                    let headerDisplay = true;
                    if (headerLevitate) {
                        headerDisplay = !this.pattern.header.display;
                        if (this.isMobi) {
                            headerDisplay = headerDisplay && !this.pattern.header.mobiHide;
                        }
                    }

                    let headerHeight = headerLevitate ? 0 : headerDisplay ? this.headerStyleStatus.headerHeight : 0;
                    let toolBarHeight = 0;
                    let mobiFooterNavServerHeight = this.mobiFooterNavServerHeight;

                    if (this.manageMode) {
                        // 获取排除的高度 60为topBar的高度
                        toolBarHeight = this.pageTop || 0;
                        if (this.isMobi) {
                            toolBarHeight = 0;
                        }
                    }
                    let _excludeHeight = toolBarHeight;

                    // //悬停
                    // _store.state.responsiveInfo.pattern.rollingScreen.topType == 1
                    // //占位
                    // _store.state.responsiveInfo.pattern.rollingScreen.topType == 2
                    // //固定
                    // this.rollingScreen.isFixedTop
                    // //不固定
                    // this.rollingScreen.isFixedTop == false;

                    //开始计算顶部去除区域
                    //非悬停情况，即是占位情况
                    if (this.rollingScreen.topType == 2 && this.rollingScreen.isFixedTop) {
                        _excludeHeight += headerHeight;
                    }
                    top = _excludeHeight;
                    //开始计算底部去除区域
                    //底部只需要考虑底部和最后一个通栏共用的情况
                    //底部和最后一个通栏在一起的情况
                    // if (this.isLastScreen && hasFooter && this.rollingScreen.bottomType === 2) {
                    //     _excludeHeight += footerHeight;
                    // }

                    //手机底部fixed的服务栏, style = 3,是悬浮的
                    if (this.isMobi && this.hasMobiFooterNavServer && this.pattern.footNav.s != 3) {
                        _excludeHeight += mobiFooterNavServerHeight;
                    }

                    fullScreenStyle.bottom = 'auto';
                    fullScreenStyle.top = '100%';
                    if (!this.$isServer && this.$el) {
                        let previewAreaHeight =
                            !this.isMobi || !this.manageMode
                                ? document.documentElement.clientHeight
                                : `${this.jzPreviewAreaHeight}px`;
                        this.footerStyleStatus.footerHeight;
                        if (this.$el.scrollHeight > previewAreaHeight - _excludeHeight) {
                            fullScreenStyle.bottom = 'auto';
                            fullScreenStyle.overflowY = 'auto';
                            fullScreenStyle.top = this.isMobi ? `calc(100% + ${top}px)` : '100%';
                            fullScreenStyle.height = `calc(${this.clientHeight} - ${_excludeHeight}px)`;
                        }
                    }
                }
            }

            const videoBgStyle = {};
            if (this.isShowVideoBg) {
                videoBgStyle.background = 'transparent';
            }

            return Object.assign({}, backgroundStyle, fullScreenStyle, videoBgStyle);
        },
        clientHeight() {
            return !this.isMobi || !this.manageMode ? '100vh' : `${this.jzPreviewAreaHeight}px`;
        },
        // 是否开启版权信息
        isOpenCopyRight() {
            return this.copyright.type == 1;
        },
        // 是否开启制作信息
        isOpenSupport() {
            return this.footer.support == 1;
        },
        // 是否开启管理登录
        isOpenAdmin() {
            return this.footer.admin == 1;
        },
        // 是否开启备案号
        isOpenRecordation() {
            return this.footer.recordation == 1;
        },
        // 是否开启ipv6标识
        isOpenIpv6Identy() {
            return this.footer.ipv6Identy == 1 && this.hasDomainSupportIpv6;
        },
        footerRowIds() {
            return this.info.footerRowIds || [];
        },
        sysFooterRowId() {
            return this.footerRowIds.find((footerRowId) => (this.rows['row' + footerRowId] || {}).type == SYS_FOOTER);
        },
        sysFooterRow() {
            return this.rows['row' + this.sysFooterRowId] || {};
        },
        sysFooterRowCols() {
            return this.sysFooterRow.cols || [];
        },
        hasSectionFooter() {
            return (this.sysFooterRow.cols || []).some(({ mIds = [] }) => mIds.indexOf(FOOTER_MODULE_IDS) > -1);
        },
        rollingScreen() {
            return this.info.pattern.rollingScreen;
        },
        showBanner() {
            return this.info.pattern.independent.self &&
                this.info.pattern.independent.selfBanner &&
                !this.isMobi &&
                this.info.pattern.independent.open
                ? !this.info.pattern.independent.banner.hide
                : (!this.isMobi && !this.info.banner.hide) ||
                      (this.isMobi && !this.info.banner.hide && !this.info.banner.mobiHide);
        },
        activeFooterClass() {
            if (
                !this.showBanner &&
                this.info.rowIds.length === 1 &&
                this.rollingScreen.type === 1 &&
                this.rollingScreen.bottomType === 2
            ) {
                return {
                    active_page: true,
                };
            }
            if (this.isFullScreen && this.rollingScreenRowInfo.isLastScreen) {
                return {
                    active_page: true,
                };
            }
            return '';
        },
        animateName() {
            if (this.rollingScreen.bottomType == 1) {
                return 'no-animateClass';
            }
            if (this.activeFooterClass && this.rollingScreen.bottomType == 1) {
                return '';
            }
            return this.rollingScreenRowInfo.animateClass;
        },
        showFooter() {
            if (!this.isFullScreen) {
                return true;
            }
            return this.activeFooterClass;
        },
    },
    watch: {
        isOpenCopyRight() {
            this.checkAddOrRemoveSectionFooter();
        },
        isOpenSupport() {
            this.checkAddOrRemoveSectionFooter();
        },
        isOpenAdmin() {
            this.checkAddOrRemoveSectionFooter();
        },
        isOpenRecordation() {
            this.checkAddOrRemoveSectionFooter();
        },
        isOpenIpv6Identy() {
            this.checkAddOrRemoveSectionFooter();
        },
    },
    mounted() {
        initDomMutationObserver({
            node: this.$el,
            id: 'gridFooter',
            callback: () => {
                this.$store.commit('footer/changeFooterStyleStatus', {
                    status: true,
                    footerHeight: parseFloat(getComputedStyle(this.$el).height),
                });
            },
        });
    },
    methods: {
        /**
         * 检测添加或删除底部部件
         */
        checkAddOrRemoveSectionFooter() {
            // 如果任一设置项开启了
            if (
                this.isOpenCopyRight ||
                this.isOpenSupport ||
                this.isOpenAdmin ||
                this.isOpenRecordation ||
                this.isOpenIpv6Identy
            ) {
                // 如果不存在底部部件
                if (!this.hasSectionFooter) {
                    // 往底部系统行的第一列最后一个位置添加
                    ((this.sysFooterRowCols[0] || {}).mIds || []).push(FOOTER_MODULE_IDS);
                }
            } else {
                // 如果设置项都没开启，查找底部部件，找到就删除
                this.sysFooterRowCols.forEach(({ mIds = [] }) => {
                    mIds.forEach((mId, mIdIndex) => {
                        if (mId == FOOTER_MODULE_IDS) {
                            mIds.splice(mIdIndex, 1);
                        }
                    });
                });
            }
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
</script>

<style>
#gridFooter::-webkit-scrollbar {
    width: 0;
}
</style>
